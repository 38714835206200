enifed('ember-glimmer/syntax', ['exports', 'ember-debug', 'ember-environment', 'ember-glimmer/syntax/-text-area', 'ember-glimmer/syntax/dynamic-component', 'ember-glimmer/syntax/input', 'ember-glimmer/syntax/mount', 'ember-glimmer/syntax/outlet', 'ember-glimmer/syntax/render', 'ember-glimmer/syntax/utils', 'ember-glimmer/utils/bindings'], function (exports, _emberDebug, _emberEnvironment, _textArea, _dynamicComponent, _input, _mount, _outlet, _render, _utils, _bindings) {
    'use strict';

    exports.experimentalMacros = undefined;
    exports.registerMacros = registerMacros;
    exports.populateMacros = populateMacros;

    function refineInlineSyntax(name, params, hash, builder) {
        (true && !(!(builder.env.builtInHelpers[name] && builder.env.owner.hasRegistration('helper:' + name))) && (0, _emberDebug.assert)('You attempted to overwrite the built-in helper "' + name + '" which is not allowed. Please rename the helper.', !(builder.env.builtInHelpers[name] && builder.env.owner.hasRegistration('helper:' + name))));

        var definition = void 0;
        if (name.indexOf('-') > -1) {
            definition = builder.env.getComponentDefinition(name, builder.meta.templateMeta);
        }
        if (definition) {
            (0, _bindings.wrapComponentClassAttribute)(hash);
            builder.component.static(definition, [params, (0, _utils.hashToArgs)(hash), null, null]);
            return true;
        }
        return false;
    }
    function refineBlockSyntax(name, params, hash, _default, inverse, builder) {
        if (name.indexOf('-') === -1) {
            return false;
        }
        var meta = builder.meta.templateMeta;
        var definition = void 0;
        if (name.indexOf('-') > -1) {
            definition = builder.env.getComponentDefinition(name, meta);
        }
        if (definition) {
            (0, _bindings.wrapComponentClassAttribute)(hash);
            builder.component.static(definition, [params, (0, _utils.hashToArgs)(hash), _default, inverse]);
            return true;
        }
        (true && !(builder.env.hasHelper(name, meta)) && (0, _emberDebug.assert)('A component or helper named "' + name + '" could not be found', builder.env.hasHelper(name, meta)));
        (true && !(!builder.env.hasHelper(name, meta)) && (0, _emberDebug.assert)('Helpers may not be used in the block form, for example {{#' + name + '}}{{/' + name + '}}. Please use a component, or alternatively use the helper in combination with a built-in Ember helper, for example {{#if (' + name + ')}}{{/if}}.', !builder.env.hasHelper(name, meta)));

        return false;
    }
    var experimentalMacros = exports.experimentalMacros = [];
    // This is a private API to allow for experimental macros
    // to be created in user space. Registering a macro should
    // should be done in an initializer.
    function registerMacros(macro) {
        experimentalMacros.push(macro);
    }
    function populateMacros(blocks, inlines) {
        inlines.add('outlet', _outlet.outletMacro);
        inlines.add('component', _dynamicComponent.inlineComponentMacro);
        if (_emberEnvironment.ENV._ENABLE_RENDER_SUPPORT === true) {
            inlines.add('render', _render.renderMacro);
        }
        inlines.add('mount', _mount.mountMacro);
        inlines.add('input', _input.inputMacro);
        inlines.add('textarea', _textArea.textAreaMacro);
        inlines.addMissing(refineInlineSyntax);
        blocks.add('component', _dynamicComponent.blockComponentMacro);
        blocks.addMissing(refineBlockSyntax);
        for (var i = 0; i < experimentalMacros.length; i++) {
            var macro = experimentalMacros[i];
            macro(blocks, inlines);
        }
        return { blocks: blocks, inlines: inlines };
    }
});