define('ember-file-upload/helpers/file-queue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    fileQueue: Ember.inject.service(),

    compute(_, hash) {
      let queues = Ember.get(this, 'fileQueue');
      let queueName = hash['name'];
      if (queueName) {
        let queue = queues.find(queueName) || queues.create(queueName);
        return queue;
      }

      return queues;
    }
  });
});