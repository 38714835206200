define('ember-changeset-validations/utils/with-defaults', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = withDefaults;


  const assign = Ember.merge || Ember.assign;

  /**
   * Create a new object with defaults
   *
   * @public
   * @param  {Object} obj
   * @param  {Object} defaults
   * @return {Object}
   */
  function withDefaults(obj = {}, defaults = {}) {
    return assign(assign({}, defaults), obj);
  }
});