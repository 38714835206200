enifed('ember-routing/location/api', ['exports', 'ember-debug', 'ember-environment', 'ember-routing/location/util'], function (exports, _emberDebug, _emberEnvironment, _util) {
  'use strict';

  exports.default = {
    /**
     This is deprecated in favor of using the container to lookup the location
     implementation as desired.
      For example:
      ```javascript
     // Given a location registered as follows:
     container.register('location:history-test', HistoryTestLocation);
      // You could create a new instance via:
     container.lookup('location:history-test');
     ```
       @method create
      @param {Object} options
      @return {Object} an instance of an implementation of the `location` API
      @deprecated Use the container to lookup the location implementation that you
      need.
      @private
    */
    create: function (options) {
      var implementation = options && options.implementation;
      (true && !(!!implementation) && (0, _emberDebug.assert)('Location.create: you must specify a \'implementation\' option', !!implementation));


      var implementationClass = this.implementations[implementation];
      (true && !(!!implementationClass) && (0, _emberDebug.assert)('Location.create: ' + implementation + ' is not a valid implementation', !!implementationClass));


      return implementationClass.create.apply(implementationClass, arguments);
    },


    implementations: {},
    _location: _emberEnvironment.environment.location,

    /**
      Returns the current `location.hash` by parsing location.href since browsers
      inconsistently URL-decode `location.hash`.
       https://bugzilla.mozilla.org/show_bug.cgi?id=483304
       @private
      @method getHash
      @since 1.4.0
    */
    _getHash: function () {
      return (0, _util.getHash)(this.location);
    }
  };
});