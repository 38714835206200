define('ember-file-upload/system/file-reader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (options = {}) {
    let { resolve, reject, promise } = Ember.RSVP.defer(`ember-file-upload: ${options.label}`);
    let reader = new FileReader();

    reader.onload = resolve;
    reader.onerror = reject;

    let aborted;
    let cancel = function () {
      if (aborted == null) {
        aborted = Ember.RSVP.defer(`ember-file-upload: Abort ${options.label}`);
        reader.abort();
      }
      return aborted.promise;
    };
    reader.onabort = function () {
      aborted.resolve();
    };

    /**
      Reads the file and returns a promise that will
      return the blob as ArrayBuffer.
       @method readAsArrayBuffer
      @return {Promise} A promise that will return the file as an ArrayBuffer
     */

    /**
      Reads the file and returns a promise that will
      return the blob as data URL.
       This is useful for reading images to display
      as a preview in the browser.
       @method readAsDataURL
      @return {Promise} A promise that will return the file as a data URL
     */

    /**
      Reads the file and returns a promise that will
      return the blob as binary string.
       This is useful for reading images or files that
      are not plain text.
       @method readAsBinaryString
      @return {Promise} A promise that will return the file as a binary string
     */

    /**
      Reads the file and returns a promise that will
      return the blob as text.
       This is useful for reading plain text files.
       @method readAsText
      @return {Promise} A promise that will return the file as text
     */
    ['readAsArrayBuffer', 'readAsDataURL', 'readAsBinaryString', 'readAsText'].forEach(methodName => {
      this[methodName] = function (blob) {
        reader[methodName](blob);
        let p = promise.then(function () {
          return reader.result;
        }, function () {
          return Ember.RSVP.reject(reader.error);
        }, `ember-file-upload: Unpack ${options.label}`);
        p.cancel = cancel;
        return p;
      };
    });
  };
});