define('ember-power-calendar/components/power-calendar', ['exports', 'moment', 'ember-concurrency', 'ember-power-calendar/templates/components/power-calendar'], function (exports, _moment, _emberConcurrency, _powerCalendar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _powerCalendar.default,
    classNames: ['ember-power-calendar'],
    powerCalendarService: Ember.inject.service('power-calendar'),
    momentService: Ember.inject.service('moment'),
    navComponent: 'power-calendar/nav',
    daysComponent: 'power-calendar/days',
    center: null,

    // Lifecycle chooks
    init() {
      this._super(...arguments);
      let changeCenter = (newCenter, calendar, e) => {
        return this.get('changeCenterTask').perform((0, _moment.default)(newCenter), calendar, e);
      };
      this.publicActions = {
        changeCenter,
        moveCenter: (step, unit, calendar, e) => {
          let newCenter = (0, _moment.default)(this.get('currentCenter')).add(step, unit);
          return changeCenter(newCenter, calendar, e);
        },
        select: (...args) => this.send('select', ...args)
      };
      this.registerCalendar();
      let onInit = this.get('onInit');
      if (onInit) {
        onInit(this.get('publicAPI'));
      }
    },

    willDestroy() {
      this._super(...arguments);
      this.unregisterCalendar();
    },

    // CPs
    currentCenter: Ember.computed('center', function () {
      let center = this.get('center');
      if (center) {
        return (0, _moment.default)(center);
      }
      return (0, _moment.default)(this.get('selected') || this.get('powerCalendarService').getDate());
    }),

    publicAPI: Ember.computed('_publicAPI', function () {
      return this.get('_publicAPI');
    }),

    _publicAPI: Ember.computed('selected', 'currentCenter', 'locale', 'momentService.locale', 'changeCenterTask.isRunning', function () {
      return {
        uniqueId: Ember.guidFor(this),
        selected: this.get('selected'),
        loading: this.get('changeCenterTask.isRunning'),
        center: this.get('currentCenter'),
        locale: this.get('locale') || this.get('momentService.locale') || _moment.default.locale(),
        actions: this.get('publicActions')
      };
    }),

    // Actions
    actions: {
      select(day, calendar, e) {
        let action = this.get('onSelect');
        if (action) {
          action(day, calendar, e);
        }
      }
    },

    // Tasks
    changeCenterTask: (0, _emberConcurrency.task)(function* (newCenterMoment, calendar, e) {
      yield this.get('onCenterChange')({ date: newCenterMoment.toDate(), moment: newCenterMoment }, calendar, e);
    }),

    // Methods
    registerCalendar() {
      if (window) {
        window.__powerCalendars = window.__powerCalendars || {}; // TODO: weakmap??
        window.__powerCalendars[Ember.guidFor(this)] = this;
      }
    },

    unregisterCalendar() {
      if (window) {
        delete window.__powerCalendars[Ember.guidFor(this)];
      }
    }
  });
});