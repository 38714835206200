define('ember-models-table/helpers/is-equal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEqual = isEqual;
  function isEqual([left, right] /*, hash*/) {
    return left === right;
  }

  exports.default = Ember.Helper.helper(isEqual);
});