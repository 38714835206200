define('ember-power-calendar/components/power-calendar-multiple/days', ['exports', 'ember-power-calendar/components/power-calendar/days', 'ember-power-calendar/utils/computed-fallback-if-undefined'], function (exports, _days, _computedFallbackIfUndefined) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _days.default.extend({
    maxLength: (0, _computedFallbackIfUndefined.default)(Infinity),

    // Methods
    dayIsSelected(dayMoment, calendar = this.get('calendar')) {
      let selected = calendar.selected || [];
      return selected.some(d => dayMoment.isSame(d, 'day'));
    },

    dayIsDisabled(dayMoment) {
      let numSelected = this.get('calendar.selected.length') || 0;
      let maxLength = this.get('maxLength') || Infinity;
      return this._super(...arguments) || numSelected >= maxLength && !this.dayIsSelected(dayMoment);
    }
  });
});