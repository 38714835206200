enifed('ember-glimmer/setup-registry', ['exports', 'ember-babel', 'container', 'ember-environment', 'ember-glimmer/component', 'ember-glimmer/components/checkbox', 'ember-glimmer/components/link-to', 'ember-glimmer/components/text_area', 'ember-glimmer/components/text_field', 'ember-glimmer/dom', 'ember-glimmer/environment', 'ember-glimmer/renderer', 'ember-glimmer/templates/component', 'ember-glimmer/templates/outlet', 'ember-glimmer/templates/root', 'ember-glimmer/views/outlet', 'ember-glimmer/helpers/loc', 'ember/features'], function (exports, _emberBabel, _container, _emberEnvironment, _component, _checkbox, _linkTo, _text_area, _text_field, _dom, _environment, _renderer, _component2, _outlet, _root, _outlet2, _loc, _features) {
    'use strict';

    exports.setupApplicationRegistry = setupApplicationRegistry;
    exports.setupEngineRegistry = setupEngineRegistry;

    var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['template:-root'], ['template:-root']),
        _templateObject2 = (0, _emberBabel.taggedTemplateLiteralLoose)(['template:components/-default'], ['template:components/-default']),
        _templateObject3 = (0, _emberBabel.taggedTemplateLiteralLoose)(['component:-default'], ['component:-default']);

    function setupApplicationRegistry(registry) {
        registry.injection('service:-glimmer-environment', 'appendOperations', 'service:-dom-tree-construction');
        registry.injection('renderer', 'env', 'service:-glimmer-environment');
        registry.register((0, _container.privatize)(_templateObject), _root.default);
        registry.injection('renderer', 'rootTemplate', (0, _container.privatize)(_templateObject));
        registry.register('renderer:-dom', _renderer.InteractiveRenderer);
        registry.register('renderer:-inert', _renderer.InertRenderer);
        if (_emberEnvironment.environment.hasDOM) {
            registry.injection('service:-glimmer-environment', 'updateOperations', 'service:-dom-changes');
        }
        registry.register('service:-dom-changes', {
            create: function (_ref) {
                var document = _ref.document;

                return new _dom.DOMChanges(document);
            }
        });
        registry.register('service:-dom-tree-construction', {
            create: function (_ref2) {
                var document = _ref2.document;

                var Implementation = _emberEnvironment.environment.hasDOM ? _dom.DOMTreeConstruction : _dom.NodeDOMTreeConstruction;
                return new Implementation(document);
            }
        });
    }
    function setupEngineRegistry(registry) {
        registry.register('view:-outlet', _outlet2.default);
        registry.register('template:-outlet', _outlet.default);
        registry.injection('view:-outlet', 'template', 'template:-outlet');
        registry.injection('service:-dom-changes', 'document', 'service:-document');
        registry.injection('service:-dom-tree-construction', 'document', 'service:-document');
        registry.register((0, _container.privatize)(_templateObject2), _component2.default);
        registry.register('service:-glimmer-environment', _environment.default);
        registry.injection('template', 'env', 'service:-glimmer-environment');
        registry.optionsForType('helper', { instantiate: false });
        registry.register('helper:loc', _loc.default);
        registry.register('component:-text-field', _text_field.default);
        registry.register('component:-text-area', _text_area.default);
        registry.register('component:-checkbox', _checkbox.default);
        registry.register('component:link-to', _linkTo.default);
        if (!_features.EMBER_GLIMMER_TEMPLATE_ONLY_COMPONENTS) {
            registry.register((0, _container.privatize)(_templateObject3), _component.default);
        }
    }
});