define('ember-power-select-with-create/components/power-select-with-create/suggested-option', ['exports', 'ember-power-select-with-create/templates/components/power-select-with-create/suggested-option'], function (exports, _suggestedOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _suggestedOption.default
  });
});