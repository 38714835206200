define('ember-changeset/utils/assign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = pureAssign;
  // @flow

  const assign = Ember.assign || Object.assign || _assign;

  function _assign(origin, ...sources) {
    return sources.reduce((acc, source) => Ember.merge(acc, source), Ember.merge({}, origin));
  }

  function pureAssign(...objects /*: Array<Object> */) /*: Object */{
    return assign({}, ...objects);
  }
});