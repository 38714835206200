define('ember-file-upload/system/data-transfer', ['exports', 'ember-file-upload/system/trim'], function (exports, _trim) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const getDataSupport = {};

  exports.default = Ember.Object.extend({

    dataTransfer: null,

    queue: null,

    getData(type) {
      let dataTransfer = Ember.get(this, 'dataTransfer');
      if (getDataSupport[type] == null) {
        try {
          let data = dataTransfer.getData(type);
          getDataSupport[type] = true;
          return data;
        } catch (e) {
          getDataSupport[type] = false;
        }
      } else if (getDataSupport[type]) {
        return dataTransfer.getData(type);
      }
    },

    valid: Ember.computed('dataTransfer.files', 'files', {
      get() {
        if (Ember.get(this, 'files') == null) {
          return true;
        }

        return (Ember.get(this, 'dataTransfer.items.length') || Ember.get(this, 'dataTransfer.files.length')) === Ember.get(this, 'files.length');
      }
    }),

    files: Ember.computed('queue.{accept,multiple}', 'dataTransfer', {
      get() {
        let fileList = Ember.get(this, 'dataTransfer.files');
        let itemList = Ember.get(this, 'dataTransfer.items');

        if (fileList == null && itemList || itemList != null && fileList != null && itemList.length > fileList.length) {
          fileList = itemList;
        }

        if (fileList == null) {
          return null;
        }

        let files = Ember.A();
        if (!Ember.get(this, 'queue.multiple') && fileList.length > 1) {
          files.push(fileList[0]);
        } else {
          for (let i = 0, len = fileList.length; i < len; i++) {
            files.push(fileList[i]);
          }
        }

        let accept = Ember.get(this, 'queue.accept');
        if (accept == null) {
          return files;
        }

        let tokens = Ember.A(accept.split(',').map(function (token) {
          return (0, _trim.default)(token).toLowerCase();
        }));
        let extensions = Ember.A(tokens.filter(function (token) {
          return token.indexOf('.') === 0;
        }));
        let mimeTypes = Ember.A(Ember.A(tokens.filter(function (token) {
          return token.indexOf('.') !== 0;
        })).map(function (mimeType) {
          return new RegExp(mimeType);
        }));

        return files.filter(function (file) {
          let extension = null;
          if (file.name && /(\.[^.]+)$/.test(file.name)) {
            extension = file.name.toLowerCase().match(/(\.[^.]+)$/)[1];
          }

          let type = file.type.toLowerCase();
          return mimeTypes.find(function (mimeType) {
            return mimeType.test(type);
          }) || extensions.indexOf(extension) !== -1;
        });
      }
    })
  });
});