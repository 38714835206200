enifed('ember-glimmer/utils/iterable', ['exports', 'ember-babel', '@glimmer/reference', 'ember-metal', 'ember-runtime', 'ember-utils', 'ember-glimmer/helpers/each-in', 'ember-glimmer/utils/references'], function (exports, _emberBabel, _reference, _emberMetal, _emberRuntime, _emberUtils, _eachIn, _references) {
    'use strict';

    exports.default = iterableFor;

    var ITERATOR_KEY_GUID = 'be277757-bbbe-4620-9fcb-213ef433cca2';
    function iterableFor(ref, keyPath) {
        if ((0, _eachIn.isEachIn)(ref)) {
            return new EachInIterable(ref, keyForEachIn(keyPath));
        } else {
            return new ArrayIterable(ref, keyForArray(keyPath));
        }
    }
    function keyForEachIn(keyPath) {
        switch (keyPath) {
            case '@index':
            case undefined:
            case null:
                return index;
            case '@identity':
                return identity;
            default:
                return function (item) {
                    return (0, _emberMetal.get)(item, keyPath);
                };
        }
    }
    function keyForArray(keyPath) {
        switch (keyPath) {
            case '@index':
                return index;
            case '@identity':
            case undefined:
            case null:
                return identity;
            default:
                return function (item) {
                    return (0, _emberMetal.get)(item, keyPath);
                };
        }
    }
    function index(_item, i) {
        return String(i);
    }
    function identity(item) {
        switch (typeof item) {
            case 'string':
            case 'number':
                return String(item);
            default:
                return (0, _emberUtils.guidFor)(item);
        }
    }
    function ensureUniqueKey(seen, key) {
        var seenCount = seen[key];
        if (seenCount > 0) {
            seen[key]++;
            return '' + key + ITERATOR_KEY_GUID + seenCount;
        } else {
            seen[key] = 1;
        }
        return key;
    }

    var ArrayIterator = function () {
        function ArrayIterator(array, length, keyFor) {
            (0, _emberBabel.classCallCheck)(this, ArrayIterator);

            this.array = array;
            this.length = length;
            this.keyFor = keyFor;
            this.position = 0;
            this.seen = Object.create(null);
        }

        ArrayIterator.from = function from(array, keyFor) {
            var length = array.length;

            if (length > 0) {
                return new this(array, array.length, keyFor);
            } else {
                return EMPTY_ITERATOR;
            }
        };

        ArrayIterator.prototype.isEmpty = function isEmpty() {
            return false;
        };

        ArrayIterator.prototype.getMemo = function getMemo(position) {
            return position;
        };

        ArrayIterator.prototype.getValue = function getValue(position) {
            return this.array[position];
        };

        ArrayIterator.prototype.next = function next() {
            var length = this.length,
                keyFor = this.keyFor,
                position = this.position,
                seen = this.seen;

            if (position >= length) {
                return null;
            }
            var value = this.getValue(position);
            var memo = this.getMemo(position);
            var key = ensureUniqueKey(seen, keyFor(value, memo));
            this.position++;
            return { key: key, value: value, memo: memo };
        };

        return ArrayIterator;
    }();

    var EmberArrayIterator = function (_ArrayIterator) {
        (0, _emberBabel.inherits)(EmberArrayIterator, _ArrayIterator);

        EmberArrayIterator.from = function from(array, keyFor) {
            var length = (0, _emberMetal.get)(array, 'length');
            if (length > 0) {
                return new this(array, length, keyFor);
            } else {
                return EMPTY_ITERATOR;
            }
        };

        function EmberArrayIterator(array, length, keyFor) {
            (0, _emberBabel.classCallCheck)(this, EmberArrayIterator);
            return (0, _emberBabel.possibleConstructorReturn)(this, _ArrayIterator.call(this, array, length, keyFor));
        }

        EmberArrayIterator.prototype.getValue = function getValue(position) {
            return (0, _emberRuntime.objectAt)(this.array, position);
        };

        return EmberArrayIterator;
    }(ArrayIterator);

    var ObjectKeysIterator = function (_ArrayIterator2) {
        (0, _emberBabel.inherits)(ObjectKeysIterator, _ArrayIterator2);

        function ObjectKeysIterator(keys, values, length, keyFor) {
            (0, _emberBabel.classCallCheck)(this, ObjectKeysIterator);

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _ArrayIterator2.call(this, values, length, keyFor));

            _this2.keys = keys;
            return _this2;
        }

        ObjectKeysIterator.from = function from(obj, keyFor) {
            var keys = Object.keys(obj);
            var length = keys.length;

            if (length > 0) {
                return new this(keys, keys.map(function (key) {
                    return obj[key];
                }), length, keyFor);
            } else {
                return EMPTY_ITERATOR;
            }
        };

        ObjectKeysIterator.prototype.getMemo = function getMemo(position) {
            return this.keys[position];
        };

        return ObjectKeysIterator;
    }(ArrayIterator);

    var EmptyIterator = function () {
        function EmptyIterator() {
            (0, _emberBabel.classCallCheck)(this, EmptyIterator);
        }

        EmptyIterator.prototype.isEmpty = function isEmpty() {
            return true;
        };

        EmptyIterator.prototype.next = function next() {
            throw new Error('Cannot call next() on an empty iterator');
        };

        return EmptyIterator;
    }();

    var EMPTY_ITERATOR = new EmptyIterator();

    var EachInIterable = function () {
        function EachInIterable(ref, keyFor) {
            (0, _emberBabel.classCallCheck)(this, EachInIterable);

            this.ref = ref;
            this.keyFor = keyFor;
            var valueTag = this.valueTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            this.tag = (0, _reference.combine)([ref.tag, valueTag]);
        }

        EachInIterable.prototype.iterate = function iterate() {
            var ref = this.ref,
                keyFor = this.keyFor,
                valueTag = this.valueTag;

            var iterable = ref.value();
            valueTag.inner.update((0, _emberMetal.tagFor)(iterable));
            if ((0, _emberMetal.isProxy)(iterable)) {
                iterable = (0, _emberMetal.get)(iterable, 'content');
            }
            var typeofIterable = typeof iterable;
            if (iterable !== null && (typeofIterable === 'object' || typeofIterable === 'function')) {
                return ObjectKeysIterator.from(iterable, keyFor);
            } else {
                return EMPTY_ITERATOR;
            }
        };

        EachInIterable.prototype.valueReferenceFor = function valueReferenceFor(item) {
            return new _references.UpdatablePrimitiveReference(item.memo);
        };

        EachInIterable.prototype.updateValueReference = function updateValueReference(reference, item) {
            reference.update(item.memo);
        };

        EachInIterable.prototype.memoReferenceFor = function memoReferenceFor(item) {
            return new _references.UpdatableReference(item.value);
        };

        EachInIterable.prototype.updateMemoReference = function updateMemoReference(reference, item) {
            reference.update(item.value);
        };

        return EachInIterable;
    }();

    var ArrayIterable = function () {
        function ArrayIterable(ref, keyFor) {
            (0, _emberBabel.classCallCheck)(this, ArrayIterable);

            this.ref = ref;
            this.keyFor = keyFor;
            var valueTag = this.valueTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            this.tag = (0, _reference.combine)([ref.tag, valueTag]);
        }

        ArrayIterable.prototype.iterate = function iterate() {
            var ref = this.ref,
                keyFor = this.keyFor,
                valueTag = this.valueTag;

            var iterable = ref.value();
            valueTag.inner.update((0, _emberMetal.tagForProperty)(iterable, '[]'));
            if (iterable === null || typeof iterable !== 'object') {
                return EMPTY_ITERATOR;
            }
            if (Array.isArray(iterable)) {
                return ArrayIterator.from(iterable, keyFor);
            } else if ((0, _emberRuntime.isEmberArray)(iterable)) {
                return EmberArrayIterator.from(iterable, keyFor);
            } else if (typeof iterable.forEach === 'function') {
                var array = [];
                iterable.forEach(function (item) {
                    return array.push(item);
                });
                return ArrayIterator.from(array, keyFor);
            } else {
                return EMPTY_ITERATOR;
            }
        };

        ArrayIterable.prototype.valueReferenceFor = function valueReferenceFor(item) {
            return new _references.UpdatableReference(item.value);
        };

        ArrayIterable.prototype.updateValueReference = function updateValueReference(reference, item) {
            reference.update(item.value);
        };

        ArrayIterable.prototype.memoReferenceFor = function memoReferenceFor(item) {
            return new _references.UpdatablePrimitiveReference(item.memo);
        };

        ArrayIterable.prototype.updateMemoReference = function updateMemoReference(reference, item) {
            reference.update(item.memo);
        };

        return ArrayIterable;
    }();
});