define('ember-validators/collection', ['exports', 'ember-validators/utils/validation-error'], function (exports, _validationError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateCollection;


  /**
   *  @class Collection
   *  @module Validators
   */

  /**
    * @method validate
    * @param {Any} value
    * @param {Object} options
    * @param {Boolean} options.collection
    * @param {Object} model
    * @param {String} attribute
    */
  function validateCollection(value, options, model, attribute) {
    let collection = Ember.get(options, 'collection');

    (true && !(Ember.isPresent(collection)) && Ember.assert(`[validator:collection] [${attribute}] option 'collection' is required`, Ember.isPresent(collection)));


    if (collection === true && !Ember.isArray(value)) {
      return (0, _validationError.default)('collection', value, options);
    }

    if (collection === false && Ember.isArray(value)) {
      return (0, _validationError.default)('singular', value, options);
    }

    return true;
  }
});