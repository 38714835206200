define('ember-file-upload/components/file-dropzone/component', ['exports', 'ember-file-upload/components/file-dropzone/template', 'ember-file-upload/system/data-transfer', 'ember-file-upload/system/uuid', 'ember-file-upload/system/drag-listener'], function (exports, _template, _dataTransfer, _uuid, _dragListener) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const DATA_TRANSFER = 'DATA_TRANSFER' + _uuid.default.short(); /* global Blob, Uint8Array */


  let supported = function () {
    return typeof window !== 'undefined' && window.document && 'draggable' in document.createElement('span');
  }();

  const dragListener = new _dragListener.default();

  /**
    @class file-dropzone
    @type Ember.Component
   */
  exports.default = Ember.Component.extend({

    layout: _template.default,

    /**
      The name of the queue that files should be
      added to when they get dropped.
       @attribute name
      @type string
     */
    name: null,

    supported,

    /**
      `ondragenter` is called when a file has entered
      the dropzone.
       @attribute ondragenter
      @type function
     */
    ondragenter: null,

    /**
      `ondragleave` is called when a file has left
      the dropzone.
       @attribute ondragleave
      @type function
     */
    ondragleave: null,

    /**
      `ondrop` is called when a file has been dropped.
       @attribute ondrop
      @type function
     */
    ondrop: null,

    fileQueue: Ember.inject.service(),

    /**
      Whether users can upload content
      from websites by dragging images from
      another webpage and dropping it into
      your app. The default is `false` to
      prevent cross-site scripting issues.
       @attribute allowUploadsFromWebsites
      @type boolean
      @default false
     */
    allowUploadsFromWebsites: false,

    /**
      This is the type of cursor that should
      be shown when a drag event happens.
       Corresponds to `dropEffect`.
       This is one of the following:
       - `copy`
      - `move`
      - `link`
       @attribute cursor
      @type string
      @default null
     */
    cursor: null,

    queue: Ember.computed('name', {
      get() {
        let queueName = Ember.get(this, 'name');
        let queues = Ember.get(this, 'fileQueue');
        return queues.find(queueName) || queues.create(queueName);
      }
    }),

    didInsertElement() {
      this._super();

      dragListener.addEventListeners(`#${Ember.get(this, 'elementId')}`, {
        dragenter: Ember.run.bind(this, 'didEnterDropzone'),
        dragleave: Ember.run.bind(this, 'didLeaveDropzone'),
        dragover: Ember.run.bind(this, 'didDragOver'),
        drop: Ember.run.bind(this, 'didDrop')
      });
    },

    willDestroyElement() {
      dragListener.removeEventListeners(`#${Ember.get(this, 'elementId')}`);
    },

    isAllowed() {
      return Ember.get(this[DATA_TRANSFER], 'source') === 'os' || Ember.get(this, 'allowUploadsFromWebsites');
    },

    didEnterDropzone(evt) {
      let dataTransfer = _dataTransfer.default.create({
        queue: Ember.get(this, 'queue'),
        source: evt.source,
        dataTransfer: evt.dataTransfer
      });
      this[DATA_TRANSFER] = dataTransfer;

      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = Ember.get(this, 'cursor');
        Ember.set(this, 'active', true);
        Ember.set(this, 'valid', Ember.get(dataTransfer, 'valid'));

        if (this.ondragenter) {
          this.ondragenter(dataTransfer);
        }
      }
    },

    didLeaveDropzone(evt) {
      Ember.set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      if (this.isAllowed()) {
        if (evt.dataTransfer) {
          evt.dataTransfer.dropEffect = Ember.get(this, 'cursor');
        }
        if (this.ondragleave) {
          this.ondragleave(this[DATA_TRANSFER]);
          this[DATA_TRANSFER] = null;
        }

        Ember.set(this, 'active', false);
      }
    },

    didDragOver(evt) {
      Ember.set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);
      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = Ember.get(this, 'cursor');
      }
    },

    didDrop(evt) {
      Ember.set(this[DATA_TRANSFER], 'dataTransfer', evt.dataTransfer);

      if (!this.isAllowed()) {
        evt.dataTransfer.dropEffect = Ember.get(this, 'cursor');
        this[DATA_TRANSFER] = null;
        return;
      }

      // Testing support for dragging and dropping images
      // from other browser windows
      let url;

      let html = this[DATA_TRANSFER].getData('text/html');
      if (html) {
        let img = Ember.$(html)[1];
        if (img.tagName === 'IMG') {
          url = img.src;
        }
      }

      if (url == null) {
        url = this[DATA_TRANSFER].getData('text/uri-list');
      }

      if (url) {
        var image = new Image();
        var [filename] = url.split('/').slice(-1);
        image.crossOrigin = 'anonymous';
        image.onload = () => {
          var canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;

          var ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);

          if (canvas.toBlob) {
            canvas.toBlob(blob => {
              let [file] = Ember.get(this, 'queue')._addFiles([blob], 'web');
              Ember.set(file, 'name', filename);
            });
          } else {
            let binStr = atob(canvas.toDataURL().split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            let blob = new Blob([arr], { type: 'image/png' });
            blob.name = filename;
            let [file] = Ember.get(this, 'queue')._addFiles([blob], 'web');
            Ember.set(file, 'name', filename);
          }
        };
        /* eslint-disable no-console */
        image.onerror = function (e) {
          console.log(e);
        };
        /* eslint-enable no-console */
        image.src = url;
      }

      if (this.ondrop) {
        this.ondrop(this[DATA_TRANSFER]);
      }

      // Add file(s) to upload queue.
      Ember.set(this, 'active', false);
      Ember.get(this, 'queue')._addFiles(Ember.get(this[DATA_TRANSFER], 'files'), 'drag-and-drop');
      this[DATA_TRANSFER] = null;
    }
  });
});