define('ember-power-calendar/services/power-calendar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    date: null,

    // Methods
    getDate() {
      return this.get('date') || new Date();
    }
  });
});