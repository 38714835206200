enifed('ember-template-compiler/plugins/assert-reserved-named-arguments', ['exports', 'ember-debug', 'ember/features', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _features, _calculateLocationDisplay) {
  'use strict';

  exports.default = assertReservedNamedArguments;


  var RESERVED = ['@arguments', '@args'];

  var isReserved = void 0,
      assertMessage = void 0;

  function assertReservedNamedArguments(env) {
    var moduleName = env.meta.moduleName;


    return {
      name: 'assert-reserved-named-arguments',

      visitors: {
        PathExpression: function (_ref) {
          var original = _ref.original,
              loc = _ref.loc;

          if (isReserved(original)) {
            (true && !(false) && (0, _emberDebug.assert)(assertMessage(original) + ' ' + (0, _calculateLocationDisplay.default)(moduleName, loc)));
          }
        }
      }
    };
  }

  if (_features.EMBER_GLIMMER_NAMED_ARGUMENTS) {
    isReserved = function (name) {
      return RESERVED.indexOf(name) !== -1 || name.match(/^@[^a-z]/);
    };
    assertMessage = function (name) {
      return '\'' + name + '\' is reserved.';
    };
  } else {
    isReserved = function (name) {
      return name[0] === '@';
    };
    assertMessage = function (name) {
      return '\'' + name + '\' is not a valid path.';
    };
  }
});