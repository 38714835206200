enifed('ember-glimmer/utils/bindings', ['exports', 'ember-babel', '@glimmer/reference', '@glimmer/wire-format', 'ember-debug', 'ember-metal', 'ember-runtime', 'ember-glimmer/component', 'ember-glimmer/utils/string'], function (exports, _emberBabel, _reference, _wireFormat, _emberDebug, _emberMetal, _emberRuntime, _component, _string) {
    'use strict';

    exports.ClassNameBinding = exports.IsVisibleBinding = exports.AttributeBinding = undefined;
    exports.wrapComponentClassAttribute = wrapComponentClassAttribute;

    function referenceForKey(component, key) {
        return component[_component.ROOT_REF].get(key);
    }
    function referenceForParts(component, parts) {
        var isAttrs = parts[0] === 'attrs';
        // TODO deprecate this
        if (isAttrs) {
            parts.shift();
            if (parts.length === 1) {
                return referenceForKey(component, parts[0]);
            }
        }
        return (0, _reference.referenceFromParts)(component[_component.ROOT_REF], parts);
    }
    // TODO we should probably do this transform at build time
    function wrapComponentClassAttribute(hash) {
        if (!hash) {
            return hash;
        }
        var keys = hash[0],
            values = hash[1];

        var index = keys.indexOf('class');
        if (index !== -1) {
            var _values$index = values[index],
                type = _values$index[0];

            if (type === _wireFormat.Ops.Get || type === _wireFormat.Ops.MaybeLocal) {
                var getExp = values[index];
                var path = getExp[getExp.length - 1];
                var propName = path[path.length - 1];
                hash[1][index] = [_wireFormat.Ops.Helper, ['-class'], [getExp, propName]];
            }
        }
        return hash;
    }
    var AttributeBinding = exports.AttributeBinding = {
        parse: function (microsyntax) {
            var colonIndex = microsyntax.indexOf(':');
            if (colonIndex === -1) {
                (true && !(microsyntax !== 'class') && (0, _emberDebug.assert)('You cannot use class as an attributeBinding, use classNameBindings instead.', microsyntax !== 'class'));

                return [microsyntax, microsyntax, true];
            } else {
                var prop = microsyntax.substring(0, colonIndex);
                var attribute = microsyntax.substring(colonIndex + 1);
                (true && !(attribute !== 'class') && (0, _emberDebug.assert)('You cannot use class as an attributeBinding, use classNameBindings instead.', attribute !== 'class'));

                return [prop, attribute, false];
            }
        },
        install: function (element, component, parsed, operations) {
            var prop = parsed[0],
                attribute = parsed[1],
                isSimple = parsed[2];

            if (attribute === 'id') {
                var elementId = (0, _emberMetal.get)(component, prop);
                if (elementId === undefined || elementId === null) {
                    elementId = component.elementId;
                }
                operations.addStaticAttribute(element, 'id', elementId);
                return;
            }
            var isPath = prop.indexOf('.') > -1;
            var reference = isPath ? referenceForParts(component, prop.split('.')) : referenceForKey(component, prop);
            (true && !(!(isSimple && isPath)) && (0, _emberDebug.assert)('Illegal attributeBinding: \'' + prop + '\' is not a valid attribute name.', !(isSimple && isPath)));

            if (attribute === 'style') {
                reference = new StyleBindingReference(reference, referenceForKey(component, 'isVisible'));
            }
            operations.addDynamicAttribute(element, attribute, reference, false);
        }
    };
    var DISPLAY_NONE = 'display: none;';
    var SAFE_DISPLAY_NONE = (0, _string.htmlSafe)(DISPLAY_NONE);

    var StyleBindingReference = function (_CachedReference) {
        (0, _emberBabel.inherits)(StyleBindingReference, _CachedReference);

        function StyleBindingReference(inner, isVisible) {
            (0, _emberBabel.classCallCheck)(this, StyleBindingReference);

            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.call(this));

            _this.inner = inner;
            _this.isVisible = isVisible;
            _this.tag = (0, _reference.combine)([inner.tag, isVisible.tag]);
            return _this;
        }

        StyleBindingReference.prototype.compute = function compute() {
            var value = this.inner.value();
            var isVisible = this.isVisible.value();
            if (isVisible !== false) {
                return value;
            } else if (!value) {
                return SAFE_DISPLAY_NONE;
            } else {
                var style = value + ' ' + DISPLAY_NONE;
                return (0, _string.isHTMLSafe)(value) ? (0, _string.htmlSafe)(style) : style;
            }
        };

        return StyleBindingReference;
    }(_reference.CachedReference);

    var IsVisibleBinding = exports.IsVisibleBinding = {
        install: function (element, component, operations) {
            var ref = (0, _reference.map)(referenceForKey(component, 'isVisible'), this.mapStyleValue);
            // the upstream type for addDynamicAttribute's `value` argument
            // appears to be incorrect. It is currently a Reference<string>, I
            // think it should be a Reference<string|null>.
            operations.addDynamicAttribute(element, 'style', ref, false);
        },
        mapStyleValue: function (isVisible) {
            return isVisible === false ? SAFE_DISPLAY_NONE : null;
        }
    };
    var ClassNameBinding = exports.ClassNameBinding = {
        install: function (element, component, microsyntax, operations) {
            var _microsyntax$split = microsyntax.split(':'),
                prop = _microsyntax$split[0],
                truthy = _microsyntax$split[1],
                falsy = _microsyntax$split[2];

            var isStatic = prop === '';
            if (isStatic) {
                operations.addStaticAttribute(element, 'class', truthy);
            } else {
                var isPath = prop.indexOf('.') > -1;
                var parts = isPath ? prop.split('.') : [];
                var value = isPath ? referenceForParts(component, parts) : referenceForKey(component, prop);
                var ref = void 0;
                if (truthy === undefined) {
                    ref = new SimpleClassNameBindingReference(value, isPath ? parts[parts.length - 1] : prop);
                } else {
                    ref = new ColonClassNameBindingReference(value, truthy, falsy);
                }
                // the upstream type for addDynamicAttribute's `value` argument
                // appears to be incorrect. It is currently a Reference<string>, I
                // think it should be a Reference<string|null>.
                operations.addDynamicAttribute(element, 'class', ref, false);
            }
        }
    };

    var SimpleClassNameBindingReference = function (_CachedReference2) {
        (0, _emberBabel.inherits)(SimpleClassNameBindingReference, _CachedReference2);

        function SimpleClassNameBindingReference(inner, path) {
            (0, _emberBabel.classCallCheck)(this, SimpleClassNameBindingReference);

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference2.call(this));

            _this2.inner = inner;
            _this2.path = path;
            _this2.tag = inner.tag;
            _this2.inner = inner;
            _this2.path = path;
            _this2.dasherizedPath = null;
            return _this2;
        }

        SimpleClassNameBindingReference.prototype.compute = function compute() {
            var value = this.inner.value();
            if (value === true) {
                var path = this.path,
                    dasherizedPath = this.dasherizedPath;

                return dasherizedPath || (this.dasherizedPath = _emberRuntime.String.dasherize(path));
            } else if (value || value === 0) {
                return String(value);
            } else {
                return null;
            }
        };

        return SimpleClassNameBindingReference;
    }(_reference.CachedReference);

    var ColonClassNameBindingReference = function (_CachedReference3) {
        (0, _emberBabel.inherits)(ColonClassNameBindingReference, _CachedReference3);

        function ColonClassNameBindingReference(inner) {
            var truthy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            var falsy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
            (0, _emberBabel.classCallCheck)(this, ColonClassNameBindingReference);

            var _this3 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference3.call(this));

            _this3.inner = inner;
            _this3.truthy = truthy;
            _this3.falsy = falsy;
            _this3.tag = inner.tag;
            return _this3;
        }

        ColonClassNameBindingReference.prototype.compute = function compute() {
            var inner = this.inner,
                truthy = this.truthy,
                falsy = this.falsy;

            return inner.value() ? truthy : falsy;
        };

        return ColonClassNameBindingReference;
    }(_reference.CachedReference);
});