define('ember-changeset/utils/computed/transform', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = transform /*:: <T> */;


  /*::
  import type Change from 'ember-changeset/-private/change';
  import type Err from 'ember-changeset/-private/err';
  */

  const { keys } = Object;

  /**
   * Transform an Object's values with a `transform` function.
   */
  // @flow

  function transform(dependentKey /*: string       */
  , transform /*: (T) => mixed */
  ) /*: Object */{
    return Ember.computed(dependentKey, function () {
      let obj /*: Object */ = Ember.get(this, dependentKey);
      return keys(obj).reduce((newObj, key) => {
        newObj[key] = transform(obj[key]);
        return newObj;
      }, {});
    });
  }
});