enifed('ember-glimmer/component-managers/outlet', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-metal', 'ember-utils', 'ember/features', 'ember-glimmer/utils/references', 'ember-glimmer/component-managers/abstract'], function (exports, _emberBabel, _runtime, _emberMetal, _emberUtils, _features, _references, _abstract) {
    'use strict';

    exports.OutletLayoutCompiler = exports.OutletComponentDefinition = exports.TopLevelOutletComponentDefinition = undefined;

    function instrumentationPayload(_ref) {
        var _ref$render = _ref.render,
            name = _ref$render.name,
            outlet = _ref$render.outlet;

        return { object: name + ':' + outlet };
    }
    function NOOP() {}

    var StateBucket = function () {
        function StateBucket(outletState) {
            (0, _emberBabel.classCallCheck)(this, StateBucket);

            this.outletState = outletState;
            this.instrument();
        }

        StateBucket.prototype.instrument = function instrument() {
            this.finalizer = (0, _emberMetal._instrumentStart)('render.outlet', instrumentationPayload, this.outletState);
        };

        StateBucket.prototype.finalize = function finalize() {
            var finalizer = this.finalizer;

            finalizer();
            this.finalizer = NOOP;
        };

        return StateBucket;
    }();

    var OutletComponentManager = function (_AbstractManager) {
        (0, _emberBabel.inherits)(OutletComponentManager, _AbstractManager);

        function OutletComponentManager() {
            (0, _emberBabel.classCallCheck)(this, OutletComponentManager);
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
        }

        OutletComponentManager.prototype.create = function create(environment, definition, _args, dynamicScope) {
            if (true) {
                this._pushToDebugStack('template:' + definition.template.meta.moduleName, environment);
            }
            var outletStateReference = dynamicScope.outletState = dynamicScope.outletState.get('outlets').get(definition.outletName);
            var outletState = outletStateReference.value();
            return new StateBucket(outletState);
        };

        OutletComponentManager.prototype.layoutFor = function layoutFor(definition, _bucket, env) {
            return env.getCompiledBlock(OutletLayoutCompiler, definition.template);
        };

        OutletComponentManager.prototype.getSelf = function getSelf(_ref2) {
            var outletState = _ref2.outletState;

            return new _references.RootReference(outletState.render.controller);
        };

        OutletComponentManager.prototype.didRenderLayout = function didRenderLayout(bucket) {
            bucket.finalize();
            if (true) {
                this.debugStack.pop();
            }
        };

        OutletComponentManager.prototype.getDestructor = function getDestructor() {
            return null;
        };

        return OutletComponentManager;
    }(_abstract.default);

    var MANAGER = new OutletComponentManager();

    var TopLevelOutletComponentManager = function (_OutletComponentManag) {
        (0, _emberBabel.inherits)(TopLevelOutletComponentManager, _OutletComponentManag);

        function TopLevelOutletComponentManager() {
            (0, _emberBabel.classCallCheck)(this, TopLevelOutletComponentManager);
            return (0, _emberBabel.possibleConstructorReturn)(this, _OutletComponentManag.apply(this, arguments));
        }

        TopLevelOutletComponentManager.prototype.create = function create(environment, definition, _args, dynamicScope) {
            if (true) {
                this._pushToDebugStack('template:' + definition.template.meta.moduleName, environment);
            }
            return new StateBucket(dynamicScope.outletState.value());
        };

        return TopLevelOutletComponentManager;
    }(OutletComponentManager);

    var TOP_LEVEL_MANAGER = function () {
        if (_features.EMBER_GLIMMER_REMOVE_APPLICATION_TEMPLATE_WRAPPER) {
            return new TopLevelOutletComponentManager();
        } else {
            var WrappedTopLevelOutletLayoutCompiler = function () {
                function WrappedTopLevelOutletLayoutCompiler(template) {
                    (0, _emberBabel.classCallCheck)(this, WrappedTopLevelOutletLayoutCompiler);

                    this.template = template;
                }

                WrappedTopLevelOutletLayoutCompiler.prototype.compile = function compile(builder) {
                    builder.wrapLayout(this.template);
                    builder.tag.static('div');
                    builder.attrs.static('id', (0, _emberUtils.guidFor)(this));
                    builder.attrs.static('class', 'ember-view');
                };

                return WrappedTopLevelOutletLayoutCompiler;
            }();

            WrappedTopLevelOutletLayoutCompiler.id = 'wrapped-top-level-outlet';

            var WrappedTopLevelOutletComponentManager = function (_TopLevelOutletCompon) {
                (0, _emberBabel.inherits)(WrappedTopLevelOutletComponentManager, _TopLevelOutletCompon);

                function WrappedTopLevelOutletComponentManager() {
                    (0, _emberBabel.classCallCheck)(this, WrappedTopLevelOutletComponentManager);
                    return (0, _emberBabel.possibleConstructorReturn)(this, _TopLevelOutletCompon.apply(this, arguments));
                }

                WrappedTopLevelOutletComponentManager.prototype.layoutFor = function layoutFor(definition, _bucket, env) {
                    return env.getCompiledBlock(WrappedTopLevelOutletLayoutCompiler, definition.template);
                };

                return WrappedTopLevelOutletComponentManager;
            }(TopLevelOutletComponentManager);

            return new WrappedTopLevelOutletComponentManager();
        }
    }();

    var TopLevelOutletComponentDefinition = exports.TopLevelOutletComponentDefinition = function (_ComponentDefinition) {
        (0, _emberBabel.inherits)(TopLevelOutletComponentDefinition, _ComponentDefinition);

        function TopLevelOutletComponentDefinition(instance) {
            (0, _emberBabel.classCallCheck)(this, TopLevelOutletComponentDefinition);

            var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, 'outlet', TOP_LEVEL_MANAGER, instance));

            _this4.template = instance.template;
            (0, _emberUtils.generateGuid)(_this4);
            return _this4;
        }

        return TopLevelOutletComponentDefinition;
    }(_runtime.ComponentDefinition);

    var OutletComponentDefinition = exports.OutletComponentDefinition = function (_ComponentDefinition2) {
        (0, _emberBabel.inherits)(OutletComponentDefinition, _ComponentDefinition2);

        function OutletComponentDefinition(outletName, template) {
            (0, _emberBabel.classCallCheck)(this, OutletComponentDefinition);

            var _this5 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition2.call(this, 'outlet', MANAGER, null));

            _this5.outletName = outletName;
            _this5.template = template;
            (0, _emberUtils.generateGuid)(_this5);
            return _this5;
        }

        return OutletComponentDefinition;
    }(_runtime.ComponentDefinition);

    var OutletLayoutCompiler = exports.OutletLayoutCompiler = function () {
        function OutletLayoutCompiler(template) {
            (0, _emberBabel.classCallCheck)(this, OutletLayoutCompiler);

            this.template = template;
        }

        OutletLayoutCompiler.prototype.compile = function compile(builder) {
            builder.wrapLayout(this.template);
        };

        return OutletLayoutCompiler;
    }();

    OutletLayoutCompiler.id = 'outlet';
});