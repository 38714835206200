define("ember-changeset/-private/change", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // @flow

  class Change {
    /*::
    value: mixed;
    */

    constructor(value /*: mixed */) {
      this.value = value;
    }
  }
  exports.default = Change;
});