define('ember-changeset-validations/helpers/changeset', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset/utils/is-object', 'ember-changeset/utils/is-promise'], function (exports, _emberChangeset, _emberChangesetValidations, _isObject, _isPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changeset = changeset;


  const { Helper: { helper } } = Ember;

  function changeset([model, validationMap]) {
    if ((0, _isObject.default)(validationMap)) {
      if ((0, _isPromise.default)(model)) {
        return model.then(resolved => new _emberChangeset.default(resolved, (0, _emberChangesetValidations.default)(validationMap), validationMap));
      }

      return new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validationMap), validationMap);
    }

    if ((0, _isPromise.default)(model)) {
      return model.then(resolved => new _emberChangeset.default(resolved, validationMap));
    }

    return new _emberChangeset.default(model, validationMap);
  }

  exports.default = helper(changeset);
});