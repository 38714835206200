enifed('ember-template-compiler/plugins/transform-top-level-components', ['exports'], function (exports) {
  'use strict';

  exports.default = transformTopLevelComponent;
  function transformTopLevelComponent() /* env */{
    return {
      name: 'transform-top-level-component',

      visitors: {
        Program: function (node) {
          hasSingleComponentNode(node, function (component) {
            component.tag = '@' + component.tag;
            component.isStatic = true;
          });
        }
      }
    };
  }

  function hasSingleComponentNode(program, componentCallback) {
    var loc = program.loc,
        body = program.body;

    if (!loc || loc.start.line !== 1 || loc.start.column !== 0) {
      return;
    }

    var lastComponentNode = void 0;
    var nodeCount = 0;

    for (var i = 0; i < body.length; i++) {
      var curr = body[i];

      // text node with whitespace only
      if (curr.type === 'TextNode' && /^[\s]*$/.test(curr.chars)) {
        continue;
      }

      // has multiple root elements if we've been here before
      if (nodeCount++ > 0) {
        return false;
      }

      if (curr.type === 'ComponentNode' || curr.type === 'ElementNode') {
        lastComponentNode = curr;
      }
    }

    if (!lastComponentNode) {
      return;
    }

    if (lastComponentNode.type === 'ComponentNode') {
      componentCallback(lastComponentNode);
    }
  }
});