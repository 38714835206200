define('ember-power-calendar/components/power-calendar-range', ['exports', 'moment', 'ember-power-calendar/components/power-calendar', 'ember-power-calendar/utils/computed-fallback-if-undefined'], function (exports, _moment, _powerCalendar, _computedFallbackIfUndefined) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function parseDuration(value) {
    if (value === null || _moment.default.isDuration(value)) {
      return value;
    }
    if (typeof value === 'number') {
      return _moment.default.duration(value, 'days');
    }
    if (typeof value === 'string') {
      let [, quantity, units] = value.match(/(\d+)(.*)/);
      units = units.trim() || 'days';
      return _moment.default.duration(parseInt(quantity, 10), units);
    }
  }

  exports.default = _powerCalendar.default.extend({
    daysComponent: 'power-calendar-range/days',
    minRange: (0, _computedFallbackIfUndefined.default)(_moment.default.duration(1, 'day')),
    maxRange: (0, _computedFallbackIfUndefined.default)(null),

    // CPs
    currentCenter: Ember.computed('center', function () {
      let center = this.get('center');
      if (center) {
        return (0, _moment.default)(center);
      }
      return (0, _moment.default)(this.get('selected.start') || this.get('powerCalendarService').getDate());
    }),

    minRangeDuration: Ember.computed('minRange', function () {
      return parseDuration(this.get('minRange'));
    }),

    maxRangeDuration: Ember.computed('maxRange', function () {
      return parseDuration(this.get('maxRange'));
    }),

    publicAPI: Ember.computed('_publicAPI', 'minRangeDuration', 'maxRangeDuration', function () {
      let rangeOnlyAPI = { minRange: this.get('minRangeDuration'), maxRange: this.get('maxRangeDuration') };
      return Ember.assign(rangeOnlyAPI, this.get('_publicAPI'));
    }),

    // Actions
    actions: {
      select(day, calendar, e) {
        let range = this._buildRange(day);
        let { start, end } = range.moment;
        if (start && end) {
          let { minRange, maxRange } = this.get('publicAPI');
          let diff = Math.abs(end.diff(start));
          if (diff < minRange.as('ms') || maxRange && diff > maxRange.as('ms')) {
            return;
          }
        }
        let action = this.get('onSelect');
        if (action) {
          action(range, calendar, e);
        }
      }
    },

    // Methods
    _buildRange(day) {
      let selected = this.get('publicAPI.selected') || { start: null, end: null };
      let { start, end } = Ember.getProperties(selected, 'start', 'end');
      if (start && !end) {
        let startMoment = (0, _moment.default)(start);
        if (startMoment.isAfter(day.moment)) {
          return {
            moment: { start: day.moment, end: startMoment },
            date: { start: day.date, end: startMoment.toDate() }
          };
        } else {
          return {
            moment: { start: startMoment, end: day.moment },
            date: { start: startMoment.toDate(), end: day.date }
          };
        }
      } else {
        return {
          moment: { start: day.moment, end: null },
          date: { start: day.date, end: null }
        };
      }
    }
  });
});