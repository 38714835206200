define('ember-changeset/utils/validate-nested-obj', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateNestedObj;


  const { keys } = Object; // @flow

  function validateNestedObj(label /*: string */
  , obj /*: Object */
  ) /*: void */{
    keys(obj).forEach(key => {
      key.split('.').forEach((_, i, allParts) => {
        if (i < allParts.length - 1) {
          let path = allParts.slice(0, i + 1).join('.');
          let msg = `Object "${label}" may not have keys that override each other.`;
          (true && !(!(path in obj)) && Ember.assert(msg, !(path in obj)));
        }
      });
    });
  }
});