enifed('ember-runtime/mixins/action_handler', ['exports', 'ember-metal', 'ember-debug'], function (exports, _emberMetal, _emberDebug) {
  'use strict';

  /**
    `Ember.ActionHandler` is available on some familiar classes including
    `Route`, `Component`, and `Controller`.
    (Internally the mixin is used by `Ember.CoreView`, `Ember.ControllerMixin`,
    and `Route` and available to the above classes through
    inheritance.)
  
    @class ActionHandler
    @namespace Ember
    @private
  */
  /**
  @module ember
  */

  var ActionHandler = _emberMetal.Mixin.create({
    mergedProperties: ['actions'],

    send: function (actionName) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (this.actions && this.actions[actionName]) {
        var shouldBubble = this.actions[actionName].apply(this, args) === true;
        if (!shouldBubble) {
          return;
        }
      }

      var target = (0, _emberMetal.get)(this, 'target');
      if (target) {
        (true && !(typeof target.send === 'function') && (0, _emberDebug.assert)('The `target` for ' + this + ' (' + target + ') does not have a `send` method', typeof target.send === 'function'));

        target.send.apply(target, arguments);
      }
    }
  });

  exports.default = ActionHandler;
});