define('ember-assign-helper/helpers/assign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.assign = assign;


  const eAssign = Ember.assign || Object.assign;

  function assign(params) {
    return eAssign({}, ...params);
  }

  exports.default = Ember.Helper.helper(assign);
});