define('ember-power-select-with-create/components/power-select-multiple-with-create', ['exports', 'ember-power-select-with-create/components/power-select-with-create', 'ember-power-select-with-create/templates/components/power-select-with-create'], function (exports, _powerSelectWithCreate, _powerSelectWithCreate2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerSelectWithCreate.default.extend({
    layout: _powerSelectWithCreate2.default,
    powerSelectComponentName: 'power-select-multiple',

    selectOrCreate(selection, select) {
      let suggestion = selection.filter(option => {
        return option.__isSuggestion__;
      })[0];

      if (suggestion) {
        this.get('oncreate')(suggestion.__value__, select);
      } else {
        this.get('onchange')(selection, select);
      }
    }
  });
});