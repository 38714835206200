define('ember-changeset/utils/is-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isObject;
  function isObject(val /*: mixed */) {
    return Ember.typeOf(val) === 'object' || Ember.typeOf(val) === 'instance';
  } // @flow
});