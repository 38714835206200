// @flow

/*::
import type { ValidationResult } from 'ember-changeset/types/validation-result';

export type ValidatorFunc = ({
  key: string,
  newValue: mixed,
  oldValue: mixed,
  changes: { [string]: mixed },
  content: Object,
}) => ValidationResult | Promise<ValidationResult>;
*/
define("ember-changeset/types/validator-func", [], function () {
  "use strict";
});