define('ember-changeset/utils/computed/is-empty-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isEmptyObject;


  const { keys } = Object; // @flow

  function isEmptyObject(dependentKey /*: string */
  ) /*: boolean */{
    (true && !(Ember.isPresent(dependentKey)) && Ember.assert('`dependentKey` must be defined', Ember.isPresent(dependentKey)));


    return Ember.computed(dependentKey, function () {
      return keys(Ember.get(this, dependentKey)).length === 0;
    }).readOnly();
  }
});