define('ember-simple-auth/mixins/application-route-mixin', ['exports', 'ember-simple-auth/configuration'], function (exports, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
      The session service.
       @property session
      @readOnly
      @type SessionService
      @public
    */
    session: Ember.inject.service('session'),

    _isFastBoot: Ember.computed(function () {
      const fastboot = Ember.getOwner(this).lookup('service:fastboot');

      return fastboot ? fastboot.get('isFastBoot') : false;
    }),

    /**
      The route to transition to after successful authentication.
       @property routeAfterAuthentication
      @type String
      @default 'index'
      @public
    */
    routeAfterAuthentication: Ember.computed(function () {
      return _configuration.default.routeAfterAuthentication;
    }),

    init() {
      this._super(...arguments);
      this._subscribeToSessionEvents();
    },

    _subscribeToSessionEvents() {
      Ember.A([['authenticationSucceeded', 'sessionAuthenticated'], ['invalidationSucceeded', 'sessionInvalidated']]).forEach(([event, method]) => {
        this.get('session').on(event, Ember.run.bind(this, () => {
          this[method](...arguments);
        }));
      });
    },

    /**
      This method handles the session's
      {{#crossLink "SessionService/authenticationSucceeded:event"}}{{/crossLink}}
      event. If there is a transition that was previously intercepted by the
      {{#crossLink "AuthenticatedRouteMixin/beforeModel:method"}}
      AuthenticatedRouteMixin's `beforeModel` method{{/crossLink}} it will retry
      it. If there is no such transition, the `ember_simple_auth-redirectTarget`
      cookie will be checked for a url that represents an attemptedTransition
      that was aborted in Fastboot mode, otherwise this action transitions to the
      {{#crossLink "Configuration/routeAfterAuthentication:property"}}{{/crossLink}}.
        @method sessionAuthenticated
      @public
    */
    sessionAuthenticated() {
      const attemptedTransition = this.get('session.attemptedTransition');
      const cookies = Ember.getOwner(this).lookup('service:cookies');
      const redirectTarget = cookies.read('ember_simple_auth-redirectTarget');

      if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null);
      } else if (redirectTarget) {
        this.transitionTo(redirectTarget);
        cookies.clear('ember_simple_auth-redirectTarget');
      } else {
        this.transitionTo(this.get('routeAfterAuthentication'));
      }
    },

    /**
      This method handles the session's
      {{#crossLink "SessionService/invalidationSucceeded:event"}}{{/crossLink}}
      event. __It reloads the Ember.js application__ by redirecting the browser
      to the application's root URL so that all in-memory data (such as Ember
      Data stores etc.) gets cleared.
       If the Ember.js application will be used in an environment where the users
      don't have direct access to any data stored on the client (e.g.
      [cordova](http://cordova.apache.org)) this action can be overridden to e.g.
      simply transition to the index route.
       @method sessionInvalidated
      @public
    */
    sessionInvalidated() {
      if (!Ember.testing) {
        if (this.get('_isFastBoot')) {
          this.transitionTo(_configuration.default.baseURL);
        } else {
          window.location.replace(_configuration.default.baseURL);
        }
      }
    }
  });
});