define("ember-changeset/-private/err", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // @flow

  /*::
  import type { ValidationErr } from 'ember-changeset/types/validation-result';
  
  export type ErrLike<T> = {
    value: T,
    validation: ValidationErr,
  };
  */

  class Err {
    /*::
    value: mixed;
    validation: ValidationErr;
    */

    constructor(value /*: mixed */, validation /*: ValidationErr */) {
      this.value = value;
      this.validation = validation;
    }
  }
  exports.default = Err;
});