define('ember-changeset-validations/utils/messages', ['exports', 'ember-validators/messages'], function (exports, _messages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    String: { dasherize, capitalize }
  } = Ember;

  const assign = Ember.assign || Ember.merge;
  const Messages = assign({}, _messages.default);

  exports.default = assign(Messages, {
    // Blank and present are flipped in ember-validators. Need to flip them back here
    blank: _messages.default.present,
    present: _messages.default.blank,

    getDescriptionFor(key = '') {
      return capitalize(dasherize(key).split(/[_-]/g).join(' '));
    }
  });
});