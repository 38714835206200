define("ember-changeset/utils/take", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = take;
  // @flow

  function take(originalObj /*: Object */ = {}, keysToTake /*: Array<string> */ = []) {
    let newObj = {};

    for (let key in originalObj) {
      if (keysToTake.indexOf(key) !== -1) {
        newObj[key] = originalObj[key];
      }
    }

    return newObj;
  }
});