enifed('ember-template-compiler/plugins/deprecate-render-model', ['exports', 'ember-debug', 'ember-template-compiler/system/calculate-location-display'], function (exports, _emberDebug, _calculateLocationDisplay) {
  'use strict';

  exports.default = deprecateRenderModel;


  /*
   * Remove after 3.4 once _ENABLE_RENDER_SUPPORT flag is no
   * longer needed.
   */
  function deprecateRenderModel(env) {
    var moduleName = env.meta.moduleName;


    return {
      name: 'deprecate-render-model',

      visitors: {
        MustacheStatement: function (node) {
          if (node.path.original === 'render' && node.params.length > 1) {
            node.params.forEach(function (param) {
              if (param.type !== 'PathExpression') {
                return;
              }

              (true && !(false) && (0, _emberDebug.deprecate)(deprecationMessage(moduleName, node, param), false, {
                id: 'ember-template-compiler.deprecate-render-model',
                until: '3.0.0',
                url: 'https://emberjs.com/deprecations/v2.x#toc_model-param-in-code-render-code-helper'
              }));
            });
          }
        }
      }
    };
  }

  function deprecationMessage(moduleName, node, param) {
    var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
    var componentName = node.params[0].original;
    var modelName = param.original;
    var original = '{{render "' + componentName + '" ' + modelName + '}}';
    var preferred = '{{' + componentName + ' model=' + modelName + '}}';

    return 'Please refactor `' + original + '` to a component and invoke via' + (' `' + preferred + '`. ' + sourceInformation);
  }
});